export const environment = {
  production: true,
  appID: '1:1061914946151:web:0beb7fad4793f8620502c2',
  config: {
    apiKey: 'AIzaSyBgHEVphibhzaZ-EOXGUWyIaMXn5vNvlfM',
    authDomain: 'tradeapp-602ed.firebaseapp.com',
    databaseURL: 'https://tradeapp-602ed.firebaseio.com',
    projectId: 'tradeapp-602ed',
    storageBucket: 'tradeapp-602ed.appspot.com',
    messagingSenderId: '1061914946151',
  },
};
