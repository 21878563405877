<div class="box__wrap">
  <div class="box">
    <div class="loading">
      <div class="loading__square"></div>
      <div class="loading__square"></div>
      <div class="loading__square"></div>
      <div class="loading__square"></div>
      <div class="loading__square"></div>
      <div class="loading__square"></div>
      <div class="loading__square"></div>
    </div>
  </div>
</div>
