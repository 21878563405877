<div class="wrap" [hidden]="hidden">
  <h2>Forex API: Loading ...</h2>
  <ul class="block-center">
    <li *ngFor="let i of [0, 1, 2, 3]">
      <app-loading class="block-center"></app-loading>
    </li>
  </ul>
</div>

<div class="wrap" [hidden]="!hidden">
  <h2>Forex symbols: xEUR EURx</h2>
  <ul *ngIf="val" class="block-center">
    <li *ngFor="let i of [0, 1, 2, 3, 4, 5, 6, 7]">
      <div class="wrap">
        <br />
        <p class="symbol">{{ key[i] }}</p>
        <br />
        <hr />
        <p class="price">{{ val[i] }}</p>
        <br />
        <p class="price">1 {{ key[i] }}</p>
        <p class="price">= {{ reversedVal[i] }} EUR</p>
      </div>
    </li>
  </ul>
</div>