<div class="welcome__wrap">
  <div class="welcome">
    <h2>WELCOME</h2>
    <h3>to the digital asset trading app</h3>
  </div>
  <div class="barchart__wrap">
    <app-barchart></app-barchart>
  </div>
  <div class="btn__wrap">
    <br />
    <div class="col-2">
      <a href="#" routerLink="/login" class="btn warning">
        <span>Login</span>
      </a>
    </div>
    <p>or view demo</p>
    <div class="col-2">
      <a href="#" routerLink="/crypto" class="btn secondary">
        <span>Crypto</span>
      </a>
      <a href="#" routerLink="/trade" class="btn primary">
        <span>Forex</span>
      </a>
    </div>
  </div>
</div>