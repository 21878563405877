<div class="wrap" [hidden]="hidden">
  <h2>CryptoAPI: Loading ...</h2>
  <ul class="block-center">
    <li *ngFor="let i of [0, 1, 2, 3]">
      <app-loading class="block-center"></app-loading>
    </li>
  </ul>
</div>
<div class="wrap" [hidden]="!hidden">
  <h2>CryptoAPI: x{{ unitSymbol }}</h2>
  <ul *ngIf="datas" class="block-center">
    <li *ngFor="let dat of datas">
      <br />
      <p class="symbol">{{ dat[0].slice(1) }}</p>
      <p class="price">{{ dat[7] | currency }}</p>
      <hr />
      Volume:
      <p class="volume">{{ vol(dat) }}</p>
    </li>
  </ul>
</div>
